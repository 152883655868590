<template>
    <div class="pl-store__sub-bar-wraper">
        <p>帮助中心</p>
        <el-menu :router="true">
            <el-submenu v-for="(item, index) in navList" :key="item.id+ '-'+index" :index="index+''">
                <template #title>{{item.name}}</template>
                 <el-menu-item v-for="obj in subData[item.id]" :key="obj.id" :index="`/help/acticle/${obj.id}`">{{obj.name}}</el-menu-item>
            </el-submenu>
        </el-menu>
    </div>
</template>
<script>
export default {
    props: {
        navList: {
            type: Array,
            default: () => []
        },
        subData: {
            type: Object,
            default: () => { return {}}
        }
    }
    
}
</script>
<style lang="scss" scoped>
.pl-store__sub-bar-wraper {
    width: 260px;
    background: #fff;
    >p {
        font-size: 18px;
        font-weight: 600;
        padding: 15px;
        text-align: center;
    }
}
</style>