<template>
    <div class="pl-store__help-page-wrap">
        <MainHeader />
        <CommonTop :isShowSearch="true" />
        <div class="pl-store__help-body-box">
            <sub-bar :navList="list" :subData="subData"></sub-bar>
            <div class="pl-store__help-content-box">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
import help from '@/mixin/help.js'
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
import subBar from '@/components/subBar/index.vue'
export default {
    name: 'news',
    components: {
        MainHeader,
        CommonTop,
        subBar
    },
    mixins: [help]

}
</script>
<style lang="scss" scoped>
.pl-store__help-page-wrap {
    background: #f5f5f5;
    padding-bottom: 20px;

    .pl-store__help-body-box {
        width: 1200px;
        margin: 20px auto;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;

        .pl-store__help-content-box {
            width: 920px;
            margin-left: 20px;
            box-sizing: border-box;
        }
    }
}
</style>
