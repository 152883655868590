import { acticleClassifyList,acticleList} from '@/api/acticle.js';
export default {
    data() {
        return {
            param: {
                page: 1,
                per_page: 50,
                type: 1
            },
            list: [],
            subData: {} //文章列表
            
        }
    },
    created() {
        acticleClassifyList(this.param).then(({data})=>{
            this.list = data || []
            this.list.forEach(item => {
                acticleList({page:1,per_page:15,classifyId:item.id,type: 1}).then(res => {
                    this.$set(this.subData, item.id, res.data)
                })
            })
        })
    }
}